var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"slide-down","mode":"out-in"}},[(_vm.showMessages)?_c('chat-card',{staticClass:"chat-box-card",on:{"close":function($event){_vm.activator = false}}}):_vm._e()],1),(!_vm.hideLabel)?_c('div',{class:[
      'text-center',
      'chat-box-label',
      { active: _vm.showMessages } ]},[_c('div',{staticClass:"chat-box-label-phone"},[_vm._v(" 8-800-10-000-01 ")]),_c('div',{staticClass:"chat-box-label-title"},[_vm._v(" ЕДИНЫЙ КОНТАКТ-ЦЕНТР ")]),_c('div',{staticClass:"chat-box-label-subtitle"},[_vm._v(" ВЗАИМОДЕЙСТВИЯ С ГРАЖДАНАМИ ")])]):_vm._e(),_c('div',{staticClass:"chat-box-activator"},[(_vm.phone)?_c('a',{class:[
        'chat-box-activator-call',
        { hidden: _vm.showMessages } ],attrs:{"href":("tel:" + _vm.phone)}}):_vm._e(),_c('div',{class:[
        'pointer chat-box-activator-chat',
        { active: _vm.showMessages } ],on:{"click":_vm.onInviteClick}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }