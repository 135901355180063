<template>
  <div class="app-textarea">
    <textarea
      class="p-20"
      :rows="rows"
      :placeholder="placeholder"
      v-model="value"
      @keyup.enter="onSubmit"
      ref="textarea"
      :disabled="disabled"
      @input="onInput"
    />
    <div class="app-textarea-actions" :class="{ disabled }">
      <div
        :class="`icon icon-${icon} icon-pointer`"
        @click="onSubmit"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    rows: {
      type: String,
      default: '1',
    },
  },
  data: () => ({
    value: '',
  }),
  methods: {
    onInput() {
      this.$emit('input', this.value)
    },
    onSubmit() {
      if (this.disabled) {
        return
      }
      if (this.value.trim()) {
        this.$emit('submit', this.value)
      }
      this.value = ''
    },
  },
}
</script>
