<template>
  <div class="messages p-20" id="messages-container">
    <template v-for="(item, i) in items">
      <system-message
        :item="item"
        v-if="!item.name"
        :key="i"
      />
      <chat-message v-else :item="item" :key="i" />
    </template>
  </div>
</template>
<script>
import ChatMessage from '@/components/messages/ChatMessage'
import SystemMessage from '@/components/messages/SystemMessage'

export default {
  components: {
    ChatMessage,
    SystemMessage,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
