import Vue from 'vue'
import Vuex from 'vuex'
import types from './types'
import handleCamundaMessage from './handlers'
import {
  clearTimer,
  scrollMessages,
  sendMessage,
} from './utils'
import {
  getOperatorMessage,
  getRegularMessage,
  getRegisterMessage,
  getTypingMessage,
} from './messages'

Vue.use(Vuex)

const PING_TIMER = 10000

export default new Vuex.Store({
  state: {
    [types.state.answers]: [],
    [types.state.messages]: [
      {
        content: `Добрый день!<br>Представьтесь, пожалуйста.`,
        from: 'system',
        name: 'Система',
        timestamp: new Date().getTime(),
      },
    ],
    [types.state.opened]: false,
    [types.state.loading]: false,
    [types.state.user]: null,
    [types.state.options]: {},
    [types.state.currentOperator]: null,
    [types.state.pingTimer]: null,
    [types.state.sessionId]: null,
    [types.state.theme]: 'default',
  },
  getters: {
    [types.getters.anonymouseUser]: ({ user }) => {
      return !user || !user.fio
    },
    [types.getters.theme]: state => {
      return 'theme-' + state.theme
    },
    [types.getters.originTheme]: state => {
      return state.options.theme || 'default'
    },
  },
  mutations: {
    [types.mutations.SET_LOADING](state, loading) {
      state.loading = loading
    },
    [types.mutations.SOCKET_ONOPEN](state) {
      state.opened = true
    },
    [types.mutations.SOCKET_ONMESSAGE](state, message) {
      handleCamundaMessage(state, message)
      scrollMessages()
    },
    [types.mutations.SOCKET_ONCLOSE](state) {
      state.opened = false
      state.currentOperator = null
      state.answers = []
      clearTimer(state)
    },
    [types.mutations.SET_USER](state, user) {
      state.user = user
      state.messages = []
    },
    [types.mutations.INIT_OPTIONS](state, options) {
      state.options = options
    },
    [types.mutations.SET_PING_TIMER](state, timer) {
      state.pingTimer = timer
    },
    [types.mutations.ADD_MESSAGES](state, ...messages) {
      state.messages = [...state.messages, ...messages]
      scrollMessages()
    },
    [types.mutations.CLEAR_ANSWERS](state) {
      state.answers = []
    },
    [types.mutations.SET_THEME](state, theme) {
      state.theme = theme
    },
  },
  actions: {
    [types.actions.send]: function(
      { commit, state },
      content
    ) {
      commit(types.mutations.ADD_MESSAGES, {
        content,
        from: 'user',
        name: 'Вы',
        timestamp: new Date().getTime(),
      })
      commit(types.mutations.CLEAR_ANSWERS)
      const message = state.currentOperator
        ? getOperatorMessage(state, content)
        : getRegularMessage(state, content)
      sendMessage(message)
    },
    [types.actions.sendTyping]: function({ state }, text) {
      const message = getTypingMessage(state, text)
      sendMessage(message)
    },
    async [types.actions.register](
      { state, commit },
      user
    ) {
      commit(types.mutations.SET_LOADING, true)
      return new Promise((resolve, reject) => {
        try {
          const message = getRegisterMessage(state, user)
          sendMessage(message)
          commit(types.mutations.SET_USER, user)
          commit(types.mutations.SET_LOADING, false)
          resolve(user)
        } catch (e) {
          reject(e)
        }
      })
    },
    async [types.actions.connect]({ commit, dispatch }) {
      commit(types.mutations.SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.prototype.$connect()
        const vm = new Vue()
        vm.$socket.onopen = () => {
          commit(types.mutations.SOCKET_ONOPEN, true)
          dispatch(types.actions.startPingTimer)
          // commit(types.mutations.SET_LOADING, false)
          resolve()
        }
        vm.$socket.onerror = () => reject()
      })
    },
    [types.actions.startPingTimer]: function({ commit }) {
      commit(
        types.mutations.SET_PING_TIMER,
        setInterval(() => {
          sendMessage({
            command: 'PING',
          })
        }, PING_TIMER)
      )
    },
    [types.actions.changeTheme]: function(
      { commit },
      payload
    ) {
      if (payload) {
        commit(types.mutations.SET_THEME, payload)
      } else {
        commit(types.mutations.SET_THEME, 'default')
      }
    },
  },
  modules: {},
})
