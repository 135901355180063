<template>
  <button
    @click="$emit('click')"
    :disabled="disabled"
    class="app-button"
  >
    <div :style="{ color: fontColor }">
      <slot></slot>
    </div>
    <app-icon
      :icon="icon"
      :color="fontColor"
      :size="isInversionTheme ? 40 : size"
      v-if="icon"
    />
  </button>
</template>
<script>
import AppIcon from './AppIcon'

export default {
  name: 'app-icon-button',
  components: {
    AppIcon,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#000',
    },
    size: {
      type: Number,
      default: 16,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fontColor() {
      if (this.isInversionTheme) {
        return 'black'
      }
      return this.disabled
        ? '#666'
        : this.dark
        ? '#fff'
        : '#000'
    },
    isInversionTheme() {
      return (
        this.$store.getters.getCurrentTheme ===
        'theme-inversion'
      )
    },
  },
}
</script>
