<template>
  <div>
    <transition name="slide-down" mode="out-in">
      <chat-card
        v-if="showMessages"
        class="chat-box-card"
        @close="activator = false"
      />
    </transition>
    <div
      v-if="!hideLabel"
      :class="[
        'text-center',
        'chat-box-label',
        { active: showMessages },
      ]"
    >
      <div class="chat-box-label-phone">
        8-800-10-000-01
      </div>
      <div class="chat-box-label-title">
        ЕДИНЫЙ КОНТАКТ-ЦЕНТР
      </div>
      <div class="chat-box-label-subtitle">
        ВЗАИМОДЕЙСТВИЯ С ГРАЖДАНАМИ
      </div>
    </div>
    <div class="chat-box-activator">
      <a
        :href="`tel:${phone}`"
        :class="[
          'chat-box-activator-call',
          { hidden: showMessages },
        ]"
        v-if="phone"
      ></a>

      <div
        @click="onInviteClick"
        :class="[
          'pointer chat-box-activator-chat',
          { active: showMessages },
        ]"
      ></div>
    </div>
  </div>
</template>
<script>
import ChatCard from './ChatCard'
import types from '@/store/types'
import { mapState } from 'vuex'

export default {
  props: {
    phone: {
      type: String,
      default: null,
    },
  },
  components: {
    ChatCard,
  },
  data: () => ({
    activator: false,
  }),
  computed: {
    ...mapState([types.state.options]),
    showMessages() {
      return this.activator
    },
    hideLabel() {
      return this.options.label === false
    },
  },
  methods: {
    onInviteClick() {
      this.activator = !this.activator
    },
  },
}
</script>
